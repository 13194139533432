import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from 'gatsby'

const IndexPage = ({data}) => {
  return (
  <Layout>

  </Layout>
  )
} 


export const Head = () => <Seo title="Home" />

export default IndexPage


export const query = graphql`
query PolicyQuery {
  allDatoCmsStore {
    edges {
      node {
        name
         
      }
    }
  }
}
`